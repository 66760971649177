
import { Component } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import IpAddressForm from "@/views/BaseData/IpAddress/IpAddressForm.vue";
import { IpAddress } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { IpAddressStoreMixin } from "@/mixins/Stores//IpAddressStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ImageViewAndUpload from "@/components/media/ImageViewAndUpload.vue";

@Component({
  components: { ImageViewAndUpload, IpAddressForm },
  mixins: [UpdateMixin],
})
export default class IpAddressUpdate extends mixins(IpAddressStoreMixin) {
  protected init(): void {
    this.findOneIpAddressItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return IpAddress.resource;
  }

  public get descriptionField(): string {
    return IpAddress.description_field;
  }

  public updateItemAction(options: ICrudOptions): void {
    this.updateIpAddressItemAction(options);
  }
}
